.main{
    height: 100vh;
    width: 100%;
    background-color: #f5f5f5; 
    /* border-radius: 30px;    */
    display: flex;
    
   
    overflow: hidden;
    
}

