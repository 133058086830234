.songCard-body {
  width: 100%;
  height: 100%;
  background-color: #27354d;
  border-radius: 30px;
  border-bottom-right-radius: 0px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
