.sidebar-container{
    position: fixed;

    top: 0;
    right: 0;
    width: 200px;
    height: 10%;
    background-color: #2c3e50;
    opacity: 0.9;
    /* border-radius: 30px; */
    display: flex;
    flex-wrap: wrap;
    flex-direction: row; /*this aligns items in a row so we need to specify direction*/
    
    align-items: center;
    justify-content: space-around;
    z-index: 10;
    border-radius: 30px}
    

.logo-container{
    width: 70px;;
    height: 80px;
    display: flex;
    
}
.profile-picture{
    width: 30px;
    height: 30px;
    border-radius: 20px;
}

@media screen and (max-width: 768px)
{
    
    .profile-picture{
        width: 40px;
        height: 40px;
    }
    
}
