.widgets-body {
  width: 95%;
  height: 80%;
  margin: 2% ;
  background: rgb(30, 42, 62);
  border-radius: 30px;
  justify-content: space-evenly;
  align-items: center;
  background: linear-gradient(
    0deg,
    rgba(30, 42, 62, 1) 0%,
    rgba(54, 69, 98, 0.1) 100%
  );
  -webkit-backdrop-filter: blur(12.5px);
  backdrop-filter: blur(12.5px);
}
/* widgets.css */

.lyric-word {
  color: white; /* Spotify green */
  text-decoration: none;
  transition: color 0.3s ease;
}

.lyric-word:hover {
  color: navy; /* Lighter Spotify green */
}
@media screen and (max-width: 768px) {
  .widgets-body {
    width: 95%;
    height: 100%;
  }
  
}
