/* ProgressCircle.css */

.score-circle {
    position: relative;
    
    width: 80%;
    height: 100%;
    background-color: #97ee9a;
    justify-content: center;
    display: flex;
    border-radius: 20px;
    padding: 0.5rem;
    
  }
  
  
  
  circle {
    fill: none;
    transition: stroke-dashoffset 0.35s;
  }
  
  .background {
    stroke: #e6e6e6;
  }
  
  .progress {
    stroke: #4caf50;
  }
  
  .score {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    font-weight: bold;
  }
  