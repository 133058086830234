.login-page {
  background-color: #1e2a3e;
  opacity: 1;
  background-image: repeating-radial-gradient(
      circle at center left,
      transparent 0,
      #233656 100px
    ),
    repeating-linear-gradient(#1e2a3e55, #1e2a3e);
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    flex-direction: column;
  }
  .eduokee{
    max-width: 90%;

  }
  
  .logo {
    width: 30%;
  }
  
  .login-btn {
    width: 200px;
    padding: 15px 0px;
    text-align: center;
    background-color: #1ed790;
    border-radius: 50px;
    color: #1f1f1f;
    font-weight: 600;
    margin-top: 20%;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .login-icon{
    width: 50px;
    height: 50px;
    color: #fefefe;
  }
  
  a {
    text-decoration: none;
  }
