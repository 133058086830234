.widgetcard-body {
    position: relative;
    width: 90%;
    height: 90%;
    border-radius: 30px;
    /* preserve white space to improve lyrics readability */
    white-space: pre-wrap;
    background: rgb(30, 42, 62);
    background: linear-gradient(
      75deg,
      rgb(40, 58, 88) 0%,
      rgba(54, 69, 98, 0) 100%
    );
    padding: 1% 3%;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
  
  .widgetcard-body:hover {
    transform: scale(1.05);
  }
  
  .widget-title {
    font-size: 18px;
    font-weight: 800;
    color: #c4d0e3;
  }
  
  .widget-fade {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 84%;
    height: 5px;
    border-radius: 30px;
    background: linear-gradient(
      180deg,
      rgba(54, 69, 98, 0) 10%,
      rgba(54, 69, 98, 1) 100%
    );
    opacity: 0;
    transition: 0.5s ease-in-out;
    animation: none;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 8%;
    pointer-events: none; /* enable scrolling*/
  }
  
  .widgetcard-body:hover .widget-fade {
    opacity: 1;
  }

  .lyrics-container {
    height: 80%; /* Adjust this value to fit your needs */
    overflow: auto;
    font-size: 1.5rem;
    color: #c4d0e3;
    font-weight: 500;
    overflow-y:auto;
    /* overflow-x: hidden; */
    line-height: 1.01; 
  }
  
