.screen-container {
  width: 100%;
  height: 100%;
  background-color: #1e2a3e;
  opacity: 1;
  display: flex;
  background-image: repeating-radial-gradient(
      circle at center left,
      transparent 0,
      #233656 100px
    ),
    repeating-linear-gradient(#1e2a3e55, #1e2a3e);
}

.flex {
  display: flex;
}

@media screen and (max-width: 768px) {
  .screen-container{
    width: 100%;
    
  }
  .flex {
    display: flex;
    flex-direction: column;
  }
}
