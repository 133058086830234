a{
    text-decoration: none;
    
}
.btnBody{
    height: 80px;
    width: 80px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    /* margin: 5px auto; */
}
.btnBody:hover{
    color: blue
}
.btnActive{
    background-color: #3A758D ;
    height: 80px;
    width: 80px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
}
.btnTitle{
    margin: 4px auto;
    font-weight: 600;
    font-size: 14px;
}
