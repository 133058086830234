  .library-body {
    width: 100%;
    height: 100%;
    /* padding: 3%; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-x: auto;
    align-items: flex-start;
    
  }
  .playlist-card {
    position: relative;
    margin: 1rem;
    padding: 0.5rem;
    width: 12rem!important;
    height: 20rem;
    border-radius: 20px;
    background-color: rgb(30, 42, 62);
    border: 1px solid rgba(54, 69, 98, 0.18);
    
    background: rgb(30, 42, 62);
    background: linear-gradient(
      75deg,
      rgb(40, 58, 88) 0%,
      rgba(54, 69, 98, 0) 100%
    );
    transition: all 0.2s ease;
    cursor: pointer;
  
  }
  
  .track-card {
    position: relative;
    margin: 1rem;
    padding: 0.5rem;
    width: 100%!important;
    overflow-x: hidden;
    height: 5rem;
    border-radius: 20px;
    background-color: rgb(30, 42, 62);
    border: 1px solid rgba(54, 69, 98, 0.18);
    /* padding: 1%;
    margin-bottom: 2%; */
    background: rgb(30, 42, 62);
    background: linear-gradient(
      75deg,
      rgb(40, 58, 88) 0%,
      rgba(54, 69, 98, 0) 100%
    );
    transition: all 0.2s ease;
    cursor: pointer;
    display: flex;
    align-items: center;
  
  }

  
  .playlist-card:hover {
    transform: scale(1.1);
  }
  
  .playlist-card:hover .playlist-fade {
    opacity: 1;
  }
  
  .playlist-image {
    width: 100%;
    aspect-ratio: 1;
    border-radius: 15px;
  }
  .playlist-image-track {
    margin-right: 1rem;
    width: 5rem;
    
    aspect-ratio: 1;
    border-radius: 15px;
  }
  
  .playlist-name {
    font-weight: 800;
    font-size: 14px;
    color: #c4d0e3;
    margin: 5px 0px 10px;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    justify-content: center;
  }
  
  /* .playlist-subtitle {
    font-weight: 400;
    font-size: 12px;
    margin: 0px;
    color: #c4d0e37c;
  } */
  
  .playlist-fade {
    position: absolute;
    right: 0;
    bottom: 0;
    opacity: 0;
    width: 84%;
    height: 25%;
    border-radius: 20px;
    background: linear-gradient(
      180deg,
      rgba(54, 69, 98, 0) 10%,
      rgba(54, 69, 98, 1) 100%
    );
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 8%;
    transition: 0.5s ease;
  }
